import React from 'react';
import { NavLink } from 'react-router-dom'

const mainMenu = [
  {
    "name": "Инциденты",
    "key": "menu_control",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Карточки инцидентов",
        "key": "menu_incident_cards",
        "icon": "icon-info",
        "action": "/incident_cards",
        "rules": "rules"
      },
      {
        "name": "Поручения",
        "key": "menu_instructions",
        "icon": "icon-info",
        "action": "/instructions",
        "rules": "rules"
      },
      {
        "name": "Оповещения",
        "key": "menu_submenu_notifs",
        "icon": "icon-info",
        "action": "/submenu_notifications",
        "rules": "rules"
      }
    ]
  },
  {
    "name": "Оповещения",
    "key": "menu_notifications",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Вид оповещения",
        "key": "menu_notifications_type",
        "icon": "icon-info",
        "action": "/notifications_type",
        "rules": "rules"
      },
      {
        "name": "Список рассылки",
        "key": "menu_mailing_list",
        "icon": "icon-info",
        "action": "/mailing_list",
        "rules": "rules"
      }
    ]
  },
  {
    "name": "Архив инцидентов",
    "key": "menu_incident_archive",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Таблица закрытых инцидентов",
        "key": "menu_closed_incidents_table",
        "icon": "icon-info",
        "action": "/closed_incidents_table",
        "rules": "rules"
      }
    ]
  },
  {
    "name": "Настройки",
    "key": "menu_settings",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Объекты наблюдения",
        "key": "menu_observation_objects",
        "icon": "icon-info",
        "action": "/observation_objects",
        "rules": "rules"
      },
      {
        "name": "Типы инцидентов",
        "key": "menu_incidents_types",
        "icon": "icon-info",
        "action": "/incidents_types",
        "rules": "rules"
      },
      {
        "name": "Состояния карточки инцидента",
        "key": "menu_incident_card_status",
        "icon": "icon-info",
        "action": "/incident_card_status",
        "rules": "rules"
      },
      {
        "name": "Типы поручений",
        "key": "menu_types_of_orders",
        "icon": "icon-info",
        "action": "/types_of_orders",
        "rules": "rules"
      },
      {
        "name": "Состояния поручений",
        "key": "menu_status_of_orders",
        "icon": "icon-info",
        "action": "/status_of_orders",
        "rules": "rules"
      },
      {
        "name": "Тревожные кнопки",
        "key": "menu_alarm_buttons",
        "icon": "icon-info",
        "action": "/alarm_buttons",
        "rules": "rules"
      }
    ]
  }
]

const MenuItems = (props) => {
  if (props.items === null) return null;
  console.log(props.items);
  let v_ret = props.items.map(function (item, index) {
    if ("submenu" in item)
      return (
        <li key={item.key} className="dropdown"><a href="#"><i className={"me-2 " + item.icon}></i>{item.name}</a>
          <ul>
            <MenuItems items={item.submenu} />

          </ul>
        </li>
      );
    else
      return (
        <li key={item.key}><NavLink to={item.action}><i className={"me-2 " + item.icon}></i>{item.name}</NavLink></li>
      );
  });
  return v_ret;
}

const Menu = (props) => {
  console.log(props.menu);
  return (
    <div className="sidebar pt-0" data-bs-theme={(props.settings.themeColor ?? "light") === "light" ? "light" : "dark"}>
      <div className="site-width">
        <ul id="side-menu" className="sidebar-menu">
          {/* <MenuItems items={props.menu} /> */}
          <MenuItems items={mainMenu} />
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Menu);
