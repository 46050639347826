import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from 'datatables.net'
import { MainArea, MainAreaHeader, MainAreaBody } from "../../layout/MainArea";
import { getDataTableDom, requestToServer } from '../../utils/appUtils'
import TableDT from "../../UI/TableDT";

const ROUTE_URL = "/get_instructarchiv";

const ClosedIncidentsTable = () => {
    const [serverData, setServerData] = useState(null);
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();

    const handleAddRow = () => {
        navigate("./create");
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlStr = "./" + data.id;
            navigate(urlStr);
        });

    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
    }, []);

    if (serverData === null)
        return <MainArea loading />;

    return (
        <>
            <MainArea>
                <MainAreaHeader caption="Архив инцидентов" />
                <MainAreaBody containerClass="container-xxl">
                    {
                        // (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                    }
                    <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />

                </MainAreaBody>
            </MainArea >
            {/* {paramsModalWindow.show && <ModalIncidentCards params={paramsModalWindow} handleClose={handleCloseModalWindow} />} */}
        </>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions =
        {
            ordering: true, lengthChange: true, filter: true, paging: false, info: true, processing: true,
            order: [[1, "asc"]],
            dom: getDataTableDom("iflp", 1, 1),
            ajax: { type: "GET", url: ROUTE_URL },
            columns: [
                { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                { data: "id", title: "№ инцидента", className: "dt-body-right dt-head-center", render: DataTable.render.dataToAnchor("btnShowEdit"), },
                { data: "updated_at", title: "Дата редактирования", render: DataTable.render.date() },
                { data: "completion_at", title: "Дата завершения", render: DataTable.render.date() },
                { data: "responsible_two", title: "Ответственный", render: DataTable.render.text() },
                { data: "incident_type", title: "Тип инцидента", render: DataTable.render.text(), },
                { data: "department", title: "Описание", render: DataTable.render.text() },
                { data: "comment", title: "Комментарий", render: DataTable.render.text() },
                { data: "status", title: "Статус", render: DataTable.render.text() },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("iflp", 1, 1);
        }
        return dtOptions;
    }
}

export default ClosedIncidentsTable;
