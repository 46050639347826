import React from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { requestToServer } from '../utils/appUtils'
// import '/src/less/css/main.css'
import '../../less/css/main.css'

const Header = (props) => {

  const handleLogout = (event) => {
    event.preventDefault();
    requestToServer("/logout", { method: 'POST' }, () => {
      localStorage.removeItem('token');
      window.location = "/login";
    }, (error) => { alert(JSON.stringify(error)); }, false);
  }

  const handleToggleMenu = (event) => {
    event.preventDefault();
    document.body.classList.toggle('compact-menu');
    document.querySelector('.sidebar').classList.toggle('active');
  }

  return (
    <div id="header-fix" className="header fixed-top">
      <div className="site-width">
        <nav className="navbar navbar-expand p-0 align-items-center">
          <div className="mb-0 h-100 logo-bar d-none d-sm-flex justify-content-center"
            data-bs-theme={(props.settings.themeColor ?? "light") === "light" ? "light" : "dark"}>
            <Link to="./incident_cards">
              {/* <img style={{ width: "100%", height: "100%", objectFit: "scale-down" }} src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" /> */}
              <div className='logotext'>
                ПУ ЛСН</div>
            </Link>
          </div>
          <div className="h-100 collapse-menu-bar">
            <a href="#" onClick={handleToggleMenu}>
              <i className="icon-menu"></i>
            </a>
          </div>

          <div className="flex-grow-1 fs-6 text-center text-wrap">
            СПО ТЕСТИРОВАНИЯ И УСТРАНЕНИЯ ИНЦИДЕНТОВ
            {/* {props.operator && (<><br />{props.operator.name}</>)} */}
          </div>
          <div className="h-100 me-2 d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle as="div" bsPrefix="none" className="px-0 px-sm-2">
                <img src={process.env.PUBLIC_URL + "/images/avatarP.png"} role="button" className="img-fluid rounded-circle" style={{ minWidth: "38px", width: "38px" }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="./changepassword" className="d-flex align-items-center">
                  <span className="icon-lock me-2 fs-6"></span> Изменить пароль
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="d-flex align-items-center" onClick={handleLogout}><span className="icon-logout me-2 fs-6"></span> Выход</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </nav>
      </div >
    </div >
  );
}
export default React.memo(Header);
