import { useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import DataTable from 'datatables.net'
import { getDataTableDom } from '../utils/appUtils'
import { reducerModalWindow , ModalWindow } from "../UI/ModalWindow";
import TableDT from "../UI/TableDT";
import SelectPeriod from "../UI/SelectPeriod";

const LogList = (props) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false, className: "modal-lg", });

  const handleRefreshClick = (event, data) => {
    dtTable.dtParams = { ...data };
    dtTable.Api.ajax.reload();
  }

  const handleCloseModalWindow = (result) => {
    dispatchModalWindow({ type: 'destroy' });
  };

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    if (props.returnTable) props.returnTable(dtTable.Api);
    $(DOM).on('click', 'tbody a.sys-user-info', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlStr = "/sys_users/" + data.user_id;
      navigate(urlStr);
    });
    $(DOM).on('click', 'tbody a[name="btnShowComments"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let str = data.comment;
      try {
        str = JSON.stringify(JSON.parse(data.comment), null, 2);
      } catch (error) {
        str = data.comment;
      }
      dispatchModalWindow({ type: 'edit', id: str });
    });

  }

  return (
    <>
      {
        props.type !== "dashboard" && <SelectPeriod loading={loading} refreshClick={handleRefreshClick} params={dtTable.dtParams} />
      }
      <TableDT className={props.type === "dashboard" ? "table-sm table-borderless table-striped" : ""}
        getOptions={() => dtTable.getTableOptions(props.url, props.type, props.setLoading ?? setLoading)} afterCreate={handleAfterDTCreate} />
      {paramsModalWindow.show && <ShowComments params={paramsModalWindow} handleClose={handleCloseModalWindow} />}
    </>
  );
}

const ShowComments = ({ params, handleClose }) => {

  console.log(params);

  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={{}} urlGet="" urlSubmit="" >
      <pre>
        <div>{params.id}</div>
      </pre>
    </ModalWindow>
  );
}

let dtTable = {
  Api: null,
  dtParams: { dat_start: moment().add(-1, 'months').format("YYYY-MM-DD"), dat_end: moment().format("YYYY-MM-DD"), tz: (new Date()).getTimezoneOffset() },
  getTableOptions: (url, type, setLoading) => {
    const dtOptions =
    {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true, deferRender: true, stateSave: false,
      order: [[0, "desc"]],
      dom: getDataTableDom("lfip", 1, 1),
      ajax: {
        type: "GET", url: url, "data": () => { return dtTable.dtParams },
        "beforeSend": function () { if (setLoading) setLoading(true); },
        "complete": function () { if (setLoading) setLoading(false); },
      },
      columns: [
        {
          "data": 'created_at', "title": "Дата", "className": 'dt-nowrap', "width": "12em",
          "render": DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true)
        },
        { "data": 'ip_address', "title": "IP" },
        { "data": 'name', "title": "Событие" },
        { "data": 'comment', "title": "Описание", render: DataTable.render.dataToAnchor("btnShowComments")
        },
        {
          "data": 'login', "title": "Логин",
          "render": function (data, type, row) {
            if (type === 'display')
              return '<a class="sys-user-info" href="#" data-user-id="' + row.user_id + '">' + DataTable.render.text().display(row.login) + "</a>";
            else
              return data;
          },
        }
      ],
    };
    if (type === "dashboard") {
      dtOptions.lengthChange = dtOptions.paging = dtOptions.filter = dtOptions.info = dtOptions.processing = false;
      dtOptions.columns.splice(3, 1);
      dtOptions.scrollY = "210px";
    }
    else if (type === "all") 
    {}
    else
        dtOptions.columns.splice(4, 1);
    return dtOptions;
  }
}

export default LogList;
