import React from "react";
import { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import { requestToServer, formatServerErorrs, enterChangeFocus } from '../utils/appUtils'
import { ButtonSpinner } from "../UI/FormElements";

let result = { modalResult: 'mrCancel' };

const ModalWindow = ({ params, handleClose, initialData, urlGet, urlSubmit, children }) => {
  const [show, setShow] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(initialData.data ?? initialData);
  const [serverData, setServerData] = useState({});
  const handleKeyDown = (event) => {
    enterChangeFocus(event);
  }
  const handleChange = (par1, par2) => {
    if (loading) return;
    if ("target" in par1) {
      if (par1.target._wrapperState.initialChecked !== undefined)
        setData({ ...data, [par1.target.name]: par1.target.checked ? 1 : 0 });
      else
        setData({ ...data, [par1.target.name]: par1.target.value });
    }
    else if ("formattedValue" in par1) //react-format-number
      setData({ ...data, [par2.event.target.name]: par1.value });
    else if ("action" in par2) //react-select
      setData({ ...data, [par2.name]: (par1 === null ? null : par1.value) });
  }
  const handleCloseQuery = (event) => {
    if (loading) return;
    result.modalResult = "mrCancel";
    setShow(false);
  }
  const handleExited = () => {
    setLoaded(false);
    setLoading(false);
    handleClose(result);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({});
    setLoading(true);
    let formData = new FormData();
    for (let key in data)
      formData.append(key, data[key]);
    requestToServer(urlSubmit, { method: params.isNew ? 'POST' : 'PATCH', body: formData },
      (response) => { result.modalResult = "mrOk"; setShow(false) },
      (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }
  const afterLoadData = (response) => {
    setLoaded(true);
    if (response === null && urlGet == false) {
      setData(initialData.data ?? initialData);
      setServerData({});
    }
    else {
      let initData = initialData.data ?? initialData;
      let newData = {};
      for (let key in initData) {
        if (initialData.format && initialData.format[key] === "date")
          newData[key] = (key in response.data && response.data[key] ? response.data[key].substring(0, 10) : initData[key]);
        else
        console.log(response.data);
          newData[key] = (key in response.data ? response.data[key] : initData[key]);
      }
      setData(newData);
      setServerData(response);
    }
  }

  useEffect(() => {
    if (urlGet)
      requestToServer(urlGet, { method: 'GET' }, afterLoadData, (error) => { handleCloseQuery(); alert(JSON.stringify(error)); }, false);
    else
      afterLoadData(null);
  }, []);

  let caption = "Просмотр", mode = "R";
  if (params.isNew) {
    caption = "Добавление";
    mode = "C";
  }
  else if ((serverData.crud & 2) != 0) {
    caption = "Изменение";
    mode = "U";
  }

  if (params.show && !loaded) /* пока не загружены форму не показыаем*/
    return (
      <Modal show={params.show} className={`${params.className ?? ""}`} centered backdrop="static" keyboard={false}>
        <Modal.Body style={{ minHeight: "290px" }} >
          <div className="loader" style={{ fontSize: "70px" }}></div>
        </Modal.Body>
      </Modal >
    );
  return (
    <Modal show={show} className={`${params.className ?? ""}`} onHide={handleCloseQuery}
      onExited={handleExited} centered backdrop="static" keyboard={!(loading || !loaded)}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Modal.Header closeButton>
          <Modal.Title className="h6">{caption}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb1-1">
          <fieldset disabled={loading || !loaded || !params.show}>
            {React.cloneElement(children, { data, errors, onChange: handleChange, onKeyDown: handleKeyDown, dopParams: { serverData, mode } })}
          </fieldset>
          {errors._message_ && <div className="form-error mt-2">{errors._message_}</div>}
        </Modal.Body>
        <Modal.Footer>
          <fieldset disabled={loading || !loaded || !params.show}>
            <div>
              {(mode !== 'R') && <ButtonSpinner className="btn-sm" loading={loading}>Сохранить</ButtonSpinner>}
              <button type="button" className="btn btn-secondary btn-sm btn-our ms-2" onClick={handleCloseQuery}>Закрыть</button>
            </div>
          </fieldset>
        </Modal.Footer>
      </form>
    </Modal >
  );
}

const reducerModalWindow = (state, action) => {
  switch (action.type) {
    case 'add':
      return { ...state, show: true, id: null, isNew: true };
    case 'edit':
      return { ...state, show: true, id: action.id, isNew: false };
    case 'destroy':
      return { ...state, show: false, id: null };
    default:
      return state;
  }
}

export { ModalWindow, reducerModalWindow };
